import { registerFunctionComponent } from '/js/lib/webact.js';

function PodcastShowCell() {
  const { html, css } = this;

  html`
    <figure>
      <slot name="image"></slot>
    </figure>
    <div class="col">
      <slot name="title"></slot>
      <slot name="copyright"></slot>
    </div>
  `;

  css`
    :host {
      display: flex;
      flex-flow: column nowrap;
      cursor: pointer;
      overflow: hidden;
      width: 96px;
      height: 138.86px;
      contain: strict;
      content-visibility: auto;
      contain-intrinsic-size: 96px 138.86px;
    }

    figure {
      width: 96px;
      height: 96px;
      display: block;
      contain: strict;
      flex-shrink: 0;
      border-radius: calc(var(--gr-rem) / 4);
      margin: 0;
    }

    .col {
      display: flex;
      flex-flow: column nowrap;
      max-width: 100%;
      margin-top: calc(var(--gr-rem) / 3);
      line-height: 1rem;
    }

    ::slotted(span) {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    ::slotted(img) {
      width: 100%;
      height: 100%;
    }

    ::slotted([slot="title"]) {
      font-size: 0.8em;
    }

    ::slotted([slot="copyright"]) {
      font-size: 0.7em;
      opacity: 0.8;
      margin-top: 0.2em;
    }

    @media (min-width: 984px) {
      :host {
        width: 128px;
        height: 170.86px;
        contain-intrinsic-size: 128px 170.86px;
      }

      figure {
        width: 128px;
        height: 128px;
      }
    }
  `;
}

export default registerFunctionComponent(PodcastShowCell, {
  name: 'podcast-show-cell'
});
